<template>
  <div class="mt-10 max-w-4xl mx-auto">
    <div class="text-right">
      <router-link to="/users/add">
        <button class="h-10 px-5 my-2 text-white transition-colors duration-150 bg-green-700 rounded-lg focus:shadow-outline hover:bg-green-800">Voeg gebruiker toe</button>
      </router-link>
    </div>
    <table class='mx-auto max-w-4xl w-full whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300 overflow-hidden'>
      <thead class="bg-gray-50">
        <tr class="text-gray-600 text-left">
          <th class="font-semibold text-sm uppercase px-6 py-4">
            Naam
          </th>
          <th class="font-semibold text-sm uppercase px-6 py-4">
            Aangemaakt op
          </th>
          <th class="font-semibold text-sm uppercase px-6 py-4 text-center">
            Rollen
          </th>
          <th class="font-semibold text-sm uppercase px-6 py-4">
          </th>
        </tr>
      </thead>
      <tbody class="divide-y divide-gray-200" v-if="users">
        <tr v-for="user in users" :key="user.id">
          <td class="px-6 py-4">
            <div class="flex items-center space-x-3">
              <div>
                <p class="">
                  {{ user.name }}
                </p>
                <p class="text-gray-500 text-sm font-semibold tracking-wide">
                  {{ user.email }}
                </p>
              </div>
            </div>
          </td>
          <td class="px-6 py-4">
            <p class="">
              {{ parseDate(user.created_at) }}
            </p>
          </td>
          <td class="px-6 py-4 text-center">
            <span v-for="(role, index) in user.roles" :key="index">{{ role }}<span v-if="index < user.roles.length - 1">, </span></span>
          </td>
          <td class="px-6 py-4 text-center">
            <router-link :to="'/users/edit/' + user.id" class="text-purple-800 hover:underline">Edit</router-link>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  title: 'Gebruikers',
  name: 'Users',
  data () {
    return {
      users: null,
      error: null
    }
  },
  mounted () {
    if (!this.$store.getters.users) {
      this.$store
        .dispatch('getUsers')
        .then(() => this.setUsers())
        .catch(() => this.setError('Er is iets misgegaan'))
    } else this.setUsers()
  },
  methods: {
    setUsers () {
      this.users = this.$store.getters.users
    },
    setError (data) {
      this.error = data
    },
    parseDate (date) {
      return moment(String(date)).format('MM/DD/YYYY HH:mm')
    }
  }
}
</script>
